.LoginPage {
    background-color: rgb(152, 230, 255);
    /* background-image: url('/img/background.jpg'); */
    min-height: 100%;
    min-height: 100vh;
    display: flex;
    background-size: cover;
    align-items: center;
    justify-content: center;
}
